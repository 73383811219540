<template>
  <v-card tile light>
    <v-tabs v-model="selectedTab" color="mevo">
      <v-tab
        v-for="(group, idx) in options"
        :key="group.key"
      >
        {{ group.name }} 
        <span v-if="optionsCounts[idx] > 0">&nbsp;({{ optionsCounts[idx] }})</span>
      </v-tab>

    </v-tabs>

    <v-tabs-items v-model="selectedTab">

      <v-tab-item
        v-for="group in options"
        :key="group.key"
      >

        <checkbox-item-group
          v-model="selected"
          :options="group.items"
          :all="groupAll"
          :multiple='multiple'
        ></checkbox-item-group>

      </v-tab-item>

    </v-tabs-items>

   <v-card-actions class="flex-wrap pb-0">

    <v-btn
      :disabled="selected.length == allValues.length - disabledOptions.length"
      @click="selectAllAction"
      text color="primary"
      >{{ 'Select All' }}</v-btn>

    <v-btn
      v-if="clearable"
      :disabled="selected.length === 0"
      @click="selected = []"
      text color="primary">{{ 'Clear' }}</v-btn>

    <v-spacer></v-spacer>

    <slot name="actions"></slot>
  </v-card-actions>
  </v-card>
</template>

<script>
import difference from 'lodash/difference';
import union from 'lodash/union';
import flatten from 'lodash/flatten';
import Selectable from '@/components/mixins/Selectable';
import Clearable from '@/components/mixins/Clearable';
import CheckboxItemGroup from '@/components/ui/selection-controls/CheckboxItemGroup.vue';

export default {
  name: 'TabbedGridPicker',

  components: {
    CheckboxItemGroup,
  },

  mixins: [
    Selectable,
    Clearable,
  ],

  props: {
    tab: {
      type: Number,
      default: null,
    },

    groupAll: {
      type: Boolean,
      default: false,
    },

    multiple: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    selectedTab: {
      get() {
        return this.tab;
      },
      set(val) {
        this.$emit('update:tab', val);
      },
    },

    allValues() {
      return flatten(this.options.map(this.optionGroupValues));
    },

    groupValues() {
      return this.options[this.selectedTab].items.map(i => i.value)
    },

    disabledOptions() {
      return flatten(this.options.map(this.optionGroupDisabledValues));
    },
    optionsCounts() {
      return this.options.map(group => {
        return group.items.reduce((acc, cur ) => {
          if (this.selected.includes(cur.value)) {
            acc++;
          }

          return acc;
        }, 0)
      })
    }
  },

  methods: {
    optionGroupValues(optionGroup) {
      return optionGroup.items.map(item => item.value);
    },

    optionGroupDisabledValues(optionGroup) {
      return optionGroup.items.filter(item => item.disabled).map(item => item.value);
    },

    selectAllAction() {
      this.selected = union(this.selected, difference(this.groupValues, this.disabledOptions));
    },
  },
};
</script>
